import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useToasts } from 'react-toast-notifications';

import { ComponentProps } from './types';
import { useStore } from './store';

const Alerts: React.FC<ComponentProps> = () => {
  const store = useStore();
  const { mounted, mount, unmount } = store;

  const toast = useToasts();

  useEffect(() => {
    if (!mounted) mount(toast);

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  return null;
};

export default observer(Alerts);
