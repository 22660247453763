import { types, Instance } from 'mobx-state-tree';

const Image = types.model({
  id: types.maybeNull(types.number),
  imageName: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  externalUrl: types.maybeNull(types.string),
  videoUrl: types.maybeNull(types.string),
  imageType: types.maybeNull(types.number),
  order: types.maybeNull(types.number),
  defaultImage: types.maybeNull(types.boolean),
});

const Merchant = types.model({
  merchantId: types.maybeNull(types.number),
  storeCode: types.maybeNull(types.string),
  integrationType: types.maybeNull(types.string),
  cashOnDelivery: types.maybeNull(types.boolean),
  paymentTypes: types.maybeNull(types.array(types.string)),
});

const Product = types.model({
  id: types.maybeNull(types.number),
  price: types.maybeNull(types.number),
  quantity: types.maybeNull(types.number),
  availableQuantity: types.maybeNull(types.number),
  sku: types.maybeNull(types.string),
  productShipeable: types.maybeNull(types.boolean),
  preOrder: types.maybeNull(types.boolean),
  productVirtual: types.maybeNull(types.boolean),
  quantityOrderMaximum: types.maybeNull(types.number),
  quantityOrderMinimum: types.maybeNull(types.number),
  productIsFree: types.maybeNull(types.boolean),
  available: types.maybeNull(types.boolean),
  visible: types.maybeNull(types.boolean),
  funcRating: types.maybeNull(types.number),
  visualRating: types.maybeNull(types.number),
  productSpecifications: types.maybeNull(
    types.model({
      height: types.maybeNull(types.number),
      weight: types.maybeNull(types.number),
      length: types.maybeNull(types.number),
      width: types.maybeNull(types.number),
      model: types.maybeNull(types.string),
      manufacturer: types.maybeNull(types.string),
      dimensionUnitOfMeasure: types.maybeNull(types.string),
      weightUnitOfMeasure: types.maybeNull(types.string),
    }),
  ),
  rating: types.maybeNull(types.number),
  ratingCount: types.maybeNull(types.number),
  sortOrder: types.maybeNull(types.number),
  dateAvailable: types.maybeNull(types.string),
  refSku: types.maybeNull(types.string),
  creationDate: types.maybeNull(types.string),
  rentalDuration: types.maybeNull(types.number),
  rentalPeriod: types.maybeNull(types.number),
  description: types.maybeNull(
    types.model({
      id: types.maybeNull(types.number),
      language: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      friendlyUrl: types.maybeNull(types.string),
      keyWords: types.maybeNull(types.string),
      highlights: types.maybeNull(types.string),
      metaDescription: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
    }),
  ),
  productPrice: types.maybeNull(types.string),
  finalPrice: types.maybeNull(types.string),
  originalPrice: types.maybeNull(types.string),
  image: types.maybeNull(Image),
  images: types.maybeNull(types.array(Image)),
  merchant: types.maybeNull(Merchant),
  subTotal: types.maybeNull(types.number),
  displaySubTotal: types.maybeNull(types.string),
  selected: types.maybeNull(types.boolean),
  cartItemattributes: types.maybeNull(types.array(types.frozen())),
  color: types.maybeNull(types.string),
  storage: types.maybeNull(types.string),
  appearance: types.maybeNull(types.number),
});

export type IProduct = Instance<typeof Product>;

const Total = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  module: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  total: types.maybeNull(types.string),
  discounted: types.maybeNull(types.boolean),
});

const Cart = types.model({
  id: types.maybeNull(types.number),
  language: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  subtotal: types.maybeNull(types.number),
  displaySubTotal: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  displayTotal: types.maybeNull(types.string),
  selectedTotal: types.maybeNull(types.number),
  quantity: types.maybeNull(types.number),
  order: types.maybeNull(types.number),
  promoCode: types.maybeNull(types.string),
  products: types.maybeNull(types.array(Product)),
  totals: types.maybeNull(types.array(Total)),
  customer: types.maybeNull(types.number),
});

export type ICart = Instance<typeof Cart>;

const MergeCart = types.model({
  cartCode: types.maybeNull(types.string),
  productNum: types.maybeNull(types.number),
});

export type IMergeCart = Instance<typeof MergeCart>;

export { Cart, MergeCart };
