import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconActionClear: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={24}>
      <path d="M12,0 C18.6274,0 24,5.37258 24,12 C24,18.6274 18.6274,24 12,24 C5.37258,24 0,18.6274 0,12 C0,5.37258 5.37258,0 12,0 Z M7.29289,7.29289 C6.90237,7.68342 6.90237,8.31658 7.29289,8.70711 L10.5858,12 L7.29289,15.2929 C6.90237,15.6834 6.90237,16.3166 7.29289,16.7071 C7.68342,17.0976 8.31658,17.0976 8.70711,16.7071 L12,13.4142 L15.2929,16.7071 C15.6834,17.0976 16.3166,17.0976 16.7071,16.7071 C17.0976,16.3166 17.0976,15.6834 16.7071,15.2929 L13.4142,12 L16.7071,8.70711 C17.0976,8.31658 17.0976,7.68342 16.7071,7.29289 C16.3166,6.90237 15.6834,6.90237 15.2929,7.29289 L12,10.5858 L8.70711,7.29289 C8.31658,6.90237 7.68342,6.90237 7.29289,7.29289 Z" />
    </IconPrimitive>
  );
};

export default IconActionClear;
