import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { ApiStartCheckout } from './types';

export const Store = types.model().actions(() => ({
  getCart: flow(function* request(id) {
    const response = yield requests.getCart(id);
    return response?.data;
  }),

  startCheckout: flow(function* request({ id, data }: { id: string; data: ApiStartCheckout }) {
    const response = yield requests.startCheckout({ id, data });
    return response?.data;
  }),

  mergeCart: flow(function* request(id: string) {
    const response = yield requests.mergeCart(id);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
