import { URLS } from './constants';

import { getApiInstance } from '../axios';
import { ApiStartCheckout } from './types';
import { getAuthHeaders } from '../utils';

function getCart(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: URLS.CART.replace(':id', id),
  });
}

function startCheckout({ id, data }: { id: string; data: ApiStartCheckout }) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: URLS.START_CHECKOUT.replace(':id', id),
    data,
  });
}

function mergeCart(id?: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: URLS.MERGE_CART,
    params: {
      cartCode: id || undefined,
    },
  });
}

export default {
  getCart,
  startCheckout,
  mergeCart,
};
