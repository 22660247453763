import { types, getSnapshot, Instance, getParent, flow } from 'mobx-state-tree';

import { gtmAddPhonePopup } from 'api/analytics/market/gtm';
import checkConstraints from 'utils/checkConstraints';

import { store as apiCustomerStore, SMS_CODE_VERIFICATION_RESULTS, GetToken, IGetToken } from 'api/customer';

import { InputEventParams } from 'components/inputs/types';
import { formatPhoneValue } from 'components/inputs/InputPhoneNumber/utils';

import type { IStore as IParentStore } from '../store';

import { CONSENTS, CONSTRAINTS, FIELDS } from './constants';

const Touched = types.model(
  Object.fromEntries(Object.values(FIELDS).map((item) => [item, types.maybeNull(types.boolean)])),
);

const Values = types.model(
  Object.values(FIELDS).reduce((result, item) => {
    let type;
    switch (item) {
      case FIELDS.CONSENT:
      case FIELDS.USER_AGREEMENT:
        type = types.boolean;
        break;
      default:
        type = types.string;
    }
    return { ...result, [item]: types.maybeNull(type) };
  }, {}),
);

const Data = types.model({
  touched: Touched,
  values: Values,
  getToken: types.maybeNull(GetToken),
  verificationError: types.maybeNull(types.string),
});

export const Store = types
  .model({
    mounted: types.boolean,
    fetch: types.boolean,
    sending: types.boolean,
    repeatTimer: types.boolean,
    data: Data,
  })

  .views((self) => ({
    get parent(): any {
      const parent: IParentStore = getParent(self);
      return parent;
    },

    get captions() {
      return this.parent.captions.code;
    },

    get phone() {
      return formatPhoneValue(this.parent.data.phone);
    },

    get description() {
      return this.captions.description.replace(':phone', this.phone);
    },

    get userExist() {
      return this.parent.data.checkCustomer.userExist;
    },

    get values() {
      return getSnapshot(self.data.values);
    },

    get constraints() {
      return Object.fromEntries(
        Object.keys(CONSTRAINTS).map((item) => [
          item,
          CONSTRAINTS[item].map((constraint) => ({
            ...constraint,
            message: this.captions.constraints[constraint.message],
          })),
        ]),
      );
    },

    get getToken() {
      if (!self.data.getToken) return null;
      return getSnapshot(self.data.getToken);
    },

    get verificationResult() {
      return self.data.getToken?.confirmationCodeVerificationResult;
    },

    get disabled() {
      const verifyNum = this.verificationResult?.verifyNum;
      return Object.fromEntries(
        Object.values(FIELDS).map((item) => [item, self.sending || !self.mounted || verifyNum === 0]),
      );
    },

    get errorPage() {
      const { resultCode, verifyNum } = this.verificationResult || {};
      if (resultCode === SMS_CODE_VERIFICATION_RESULTS.MISMATCH && verifyNum === 0) {
        return true;
      }
      return resultCode === SMS_CODE_VERIFICATION_RESULTS.ERROR_SESSION_CLOSED;
    },

    get verificationErrorMessage() {
      const { resultCode, verifyNum } = this.verificationResult || {};
      if (resultCode === SMS_CODE_VERIFICATION_RESULTS.MISMATCH) {
        if (verifyNum > 0) return this.captions.verificationErrors.mismatchTryAgain.replace(':verifyNum', verifyNum);
        return this.captions.verificationErrors.mismatchNoAttempts;
      }
      if (resultCode === SMS_CODE_VERIFICATION_RESULTS.ERROR_SESSION_CLOSED) {
        return this.captions.verificationErrors.sessionClosed;
      }
      return null;
    },

    get errors() {
      return Object.values(FIELDS).reduce((result, item) => {
        if (item === FIELDS.CODE && self.data.verificationError) {
          return { ...result, [item]: this.verificationErrorMessage };
        }

        if (!this.disabled[item] && this.visibility[item] && self.data.touched[item]) {
          return {
            ...result,
            [item]: checkConstraints({
              value: self.data.values[item],
              constraints: this.constraints[item],
              otherValues: self.data.values,
              requireConditions: [],
            }),
          };
        }
        return result;
      }, {});
    },

    get hasErrors() {
      return !!Object.values(FIELDS).filter(
        (item) =>
          !this.disabled[item] &&
          this.visibility[item] &&
          checkConstraints({
            value: self.data.values[item],
            constraints: this.constraints[item],
            otherValues: self.data.values,
            requireConditions: [],
          }),
      ).length;
    },

    get consents() {
      if (this.userExist) return null;
      // if (self.data.values[FIELDS.USER_AGREEMENT]) return CONSENTS;
      // return CONSENTS.slice(0, 2);
      return CONSENTS;
    },

    get loading() {
      return self.sending || self.fetch;
    },

    get visibility() {
      const resendNum = this.verificationResult?.resendNum;
      const verifyNum = this.verificationResult?.verifyNum;
      return {
        repeatTimer: self.repeatTimer && resendNum > 0 && verifyNum > 0,
        repeatButton: !self.repeatTimer && resendNum > 0 && verifyNum > 0,
        consents: !this.userExist,
        [FIELDS.CONSENT]: !this.userExist, // check hasErrors
        [FIELDS.USER_AGREEMENT]: !this.userExist, // check hasErrors
      };
    },

    get button() {
      return {
        disabled: self.data.values[FIELDS.CODE]?.length !== 4,
        loading: self.sending,
        label: this.captions.buttons.signUp,
      };
    },
  }))
  .actions((self) => {
    const startRepeatTimer = () => {
      self.repeatTimer = true;
    };

    const stopRepeatTimer = () => {
      self.repeatTimer = false;
    };

    return {
      startRepeatTimer,
      stopRepeatTimer,
    };
  })
  .actions((self) => ({
    sendSmsCode: flow(function* sendSmsCode() {
      gtmAddPhonePopup.smsRequest();
      self.data.values[FIELDS.CODE] = null;
      self.data.touched[FIELDS.CODE] = null;
      self.data.verificationError = null;
      try {
        self.fetch = true;
        const params = {
          phone: self.parent.data.phone,
          sessionId: self.userExist ? self.verificationResult.sessionId : null,
          templateCode: self.userExist ? null : 'register_passcode_ru',
        };
        const result = yield apiCustomerStore.sendSmsCode(params, self.parent.smartTknValue);
        self.data.getToken = { confirmationCodeVerificationResult: result } as IGetToken;
        self.startRepeatTimer();
      } catch (error) {
        self.parent.setError(error);
      } finally {
        self.fetch = false;
        self.parent.setIsResetSmsCode(false);
      }
    }),

    checkPassCode: flow(function* checkPassCode() {
      try {
        self.sending = true;
        const params = {
          phone: self.parent.data.phone,
          sessionId: self.verificationResult.sessionId,
          passCode: self.data.values[FIELDS.CODE],
        };

        if (self.userExist) {
          self.data.getToken = yield apiCustomerStore.existCustomerGetToken(params);
        } else {
          self.data.getToken = yield apiCustomerStore.newCustomerGetToken({ ...params, consents: self.consents });
        }

        if (self.verificationResult.resultCode === SMS_CODE_VERIFICATION_RESULTS.MATCH) {
          gtmAddPhonePopup.success();
          yield self.parent.root.store.onSignIn(self.getToken.oauth2AccessToken);
        } else self.data.verificationError = self.verificationResult.resultCode;
      } catch (error: any) {
        self.parent.setError(error);
        self.parent.root.store.onCloseAuth();
      } finally {
        self.sending = false;
        self.parent.setCloseModal(true);
      }
    }),
  }))

  .actions((self) => {
    const mount = () => {
      gtmAddPhonePopup.added();
      self.data.getToken = {
        confirmationCodeVerificationResult: {
          resendNum: self.parent.data.checkCustomer.resendNum,
          sessionId: self.parent.data.checkCustomer.sessionId,
          verifyNum: self.parent.data.checkCustomer.verifyNum,
        },
      } as IGetToken;
      self.data.values[FIELDS.CONSENT] = true;
      self.data.values[FIELDS.USER_AGREEMENT] = true;
      self.startRepeatTimer();
      self.mounted = true;
    };

    const unmount = () => {
      self.mounted = false;
      self.fetch = false;
      self.sending = false;
      self.repeatTimer = false;
      self.data = { touched: {}, values: {}, getToken: null, verificationError: null };
    };

    const handleSubmit = () => {
      for (const item of Object.values(FIELDS)) {
        self.data.touched[item] = true;
      }
      if (self.hasErrors) return;
      self.checkPassCode();
    };

    const handleChange = ({ id, value }: InputEventParams) => {
      self.data.values[id] = value;
      if (self.userExist && id === FIELDS.CODE && self.data.values[id]?.length === 4) handleSubmit();
    };

    const handleBlur = ({ id }: InputEventParams) => {
      if (self.data.values[id]) self.data.touched[id] = true;
    };

    const handleRepeatClick = () => {
      self.parent.setIsResetSmsCode(true);
      self.parent.setVisibleCaptcha(true);
    };

    const handleGoBack = () => {
      self.parent.handleClear();
    };

    return {
      mount,
      unmount,
      handleChange,
      handleBlur,
      handleRepeatClick,
      handleGoBack,
      handleSubmit,
    };
  });

export const store = Store.create({
  mounted: false,
  fetch: false,
  sending: false,
  repeatTimer: false,
  data: { touched: {}, values: {}, getToken: null, verificationError: null },
});

export interface IStore extends Instance<typeof Store> {}
