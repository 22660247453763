const getIsMobileApp = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isMobileApp =
    localStorage.getItem('isMobileApp') === 'true' ||
    searchParams.has('mobileapp') ||
    /\/mobile\//.test(window.location.href);

  return isMobileApp;
};

export default getIsMobileApp;
